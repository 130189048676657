/* font goes here */
@font-face {
  font-family: 'Dezen Pro Regular';
  src: url('./DezenProRegular_Webfont/webFonts/DezenProRegular/font.woff2')
      format('woff2'),
    url('./DezenProRegular_Webfont/webFonts/DezenProRegular/font.woff')
      format('woff');
}

@font-face {
  font-family: 'Dezen Pro Heavy';
  src: url('./DezenProHeavy_Webfont/webFonts/DezenProHeavy/font.woff2')
      format('woff2'),
    url('./DezenProHeavy_Webfont/webFonts/DezenProHeavy/font.woff')
      format('woff');
}

@font-face {
  font-family: 'GT Planar Regular';
  src: url('./GT-Planar/GT-Planar-Regular.woff2') format('woff2'),
    url('./GT-Planar/GT-Planar-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GT Planar Light';
  src: url('./GT-Planar/GT-Planar-Light.woff2') format('woff2'),
    url('./GT-Planar/GT-Planar-Light.woff') format('woff');
}
